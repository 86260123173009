import React from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import NavbarLinkButton from './NavbarLinkButton';
import S from './navbar.module.css';

const Navbar = () => {
    return (
    <Grid className={S.navbar} spacing={6} container>
        <Grid item>
            <NavbarLinkButton text="Home" to="/" />
        </Grid>
        <Grid item>
            <NavbarLinkButton text="About" to="/about/" />
        </Grid>
    </Grid>
    );
};

export default Navbar;
