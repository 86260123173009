import React from 'react';
import { Link } from 'gatsby';
import S from './NavbarLinkButton.module.css'

const NavbarLinkButton = (props) => {
    return (
        <Link className={S.navbarLinkButton} to={props.to}>{props.text}</Link>
    )
}

export default NavbarLinkButton;
